import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CacheService } from '../services/cache.service';
import { Observable } from 'rxjs';
import {
  AppraisalRepAssetToAddInCluster,
  AppraisalReportAssetClusterIndexModel,
  AssetIdentificationDataModel,
  AssetLocalizationInformationModel,
  AssetStayAndFacilityInformationModel,
  AssetSurfacesInformationModel,
  AssetTabMassiveResultModel,
  AssetTabModel,
  AssetTabModelMassive,
  AssetTenureInformationModel,
  AssetTreeNodeExtendedIndexModel,
  AssetTypologyAndStateInformationDto,
  AssetTypologyInformationDto,
  CheckSurfacesForWorkPlanModel,
  ClusterInitialDetailModel,
  ClusterTypes,
  ClusterValorationSummary,
  CommonAssetDataIndexModel,
  FoxeetValidationFilterModel,
  GroupMassiveModel,
  GroupMassiveModelData,
  MassiveModel,
  MassiveValorationsBaseModel,
  MultipleAssetRecalculationReportDataModel,
  OrNull,
  OrUndef,
  RelatedDeliverableDto,
  ReportValorationSummary,
  ValidationModel,
  ValidationReportModel,
  ValorationMethods,
  WorkflowItemReportAssetClusterAssetsCreateModel,
  WorkflowItemReportAssetClusterEditModel,
  WorkflowItemReportAssetClusterModel,
  WorkflowItemReportAssetClusterTreeModel,
} from '@foxeet/domain';
import { WorkflowService } from '../services/workflow.service';
import { take } from 'rxjs/operators';

@Injectable()
export class AppraisalReportService extends WorkflowService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'AppraisalReports', cache);
  }

  getInitialDetails<T>(id: number): Observable<T> {
    return this.get<T>(`${id}/InitialDetails`);
  }

  duplicate(reportId: number) {
    return this.post('Duplicate', null, { params: this.setParams({ reportId }) });
  }

  generateDraftReport(reportId: number): Observable<Blob> {
    return this.get(`${reportId}/DraftReport`, { responseType: 'blob', observe: 'response' });
  }

  getTreeExtendedByReportId(reportId: number): Observable<AssetTreeNodeExtendedIndexModel[]> {
    return this.get<AssetTreeNodeExtendedIndexModel[]>(`${reportId}/TreeExtended`);
  }

  getCommonAssetDataByReportId(reportId: number): Observable<CommonAssetDataIndexModel> {
    return this.get<CommonAssetDataIndexModel>(`${reportId}/CommonAsset`);
  }

  public getAppraisalAssetsFromAppraisalRequestElements(reportId: number): Observable<AssetTreeNodeExtendedIndexModel[]> {
    return this.post<null, AssetTreeNodeExtendedIndexModel[]>(`${reportId}/AppraisalAssetsFromAppraisalRequestElements`, null);
  }

  getClusterInitialDetails(reportId: number, id: number): Observable<ClusterInitialDetailModel> {
    return this.get<ClusterInitialDetailModel>(`${reportId}/Clusters/${id}/InitialDetails`);
  }

  public createAssetCluster(reportId: number, cluster: WorkflowItemReportAssetClusterEditModel): Observable<void> {
    return this.post<WorkflowItemReportAssetClusterEditModel, void>(`${reportId}/Clusters`, cluster);
  }

  public getClusterSummary(page: number, pagesize: number, body = {}) {
    return this.post<any, any>('Clusters/ListPagedAssetsSummary', body, {
      params: this.setParams({
        page,
        pagesize,
      }),
    });
  }

  public editAssetCluster(reportId: number, cluster: WorkflowItemReportAssetClusterEditModel): Observable<void> {
    return this.put<WorkflowItemReportAssetClusterEditModel, void>(`${reportId}/Clusters/${cluster.id}`, cluster);
  }

  public listAllClusters(reportId: number): Observable<AppraisalReportAssetClusterIndexModel[]> {
    return this.get<AppraisalReportAssetClusterIndexModel[]>(`${reportId}/Clusters`);
  }

  public deleteAssetCluster(reportId: number, clusterId: number): Observable<any> {
    return this.delete<any>(`${reportId}/Clusters/${clusterId}`);
  }

  public getClusterValorationSummary(reportId: number, clusterId: number): Observable<ClusterValorationSummary> {
    return this.get<ClusterValorationSummary>(`${reportId}/Clusters/${clusterId}/ValorationSummary`);
  }

  public getClusterDetail(reportId: OrUndef<number>, clusterId: OrUndef<number>): Observable<WorkflowItemReportAssetClusterModel> {
    return this.get<WorkflowItemReportAssetClusterModel>(`${reportId}/Clusters/${clusterId}`);
  }

  public getTerrainsToAddInCluster(reportId: number): Observable<AppraisalRepAssetToAddInCluster[]> {
    return this.get<AppraisalRepAssetToAddInCluster[]>(`${reportId}/TerrainsToAddInCluster`);
  }

  public removeAssetFromCluster(reportId: number, clusterId: number, assetId: number): Observable<any> {
    return this.delete<any>(`${reportId}/Clusters/${clusterId}/Assets/${assetId}`);
  }

  addAssetToCluster(reportId: number, clusterId: number, assetIds: number[]): Observable<void> {
    const body: WorkflowItemReportAssetClusterAssetsCreateModel = {
      assetIds,
    };
    return this.put<WorkflowItemReportAssetClusterAssetsCreateModel, void>(`${reportId}/Clusters/${clusterId}/Assets`, body);
  }

  getDeliverablesByReportId(id: number): Observable<RelatedDeliverableDto> {
    return this.get<RelatedDeliverableDto>(`${id}/Deliverables`);
  }

  getValorationSummary(id: number, isFutureValoration: boolean = false): Observable<ReportValorationSummary> {
    return this.get<ReportValorationSummary>(`${id}/ValorationSummary`, { params: this.setParams({ isFutureValoration }) });
  }

  duplicateFromAppraisal(targetId: number, fromId: number): Observable<void> {
    return this.post<null, void>('DuplicateFromAppraisal', null, {
      params: this.setParams({
        targetId,
        fromId,
      }),
    });
  }

  public getAssetTreeFilteredByClusterType(reportId: number, clusterType: ClusterTypes, clusterId: OrNull<number> = null): Observable<WorkflowItemReportAssetClusterTreeModel[]> {
    return this.get<WorkflowItemReportAssetClusterTreeModel[]>(`${reportId}/Clusters/GetClusterTree`, {
      params: this.setParams({
        clusterType,
        clusterId,
      }),
    });
  }

  public isOutOfUsefulLife(reportId: number, clusterId: number, queryParams: { [key: string]: any }): Observable<CheckSurfacesForWorkPlanModel> {
    return this.post<null, CheckSurfacesForWorkPlanModel>(`${reportId}/Clusters/${clusterId}/IsOutOfUsefulLife`, null, { params: this.setParams(queryParams) });
  }

  /**
   * @Todo Circular dependency Filter @foxeet/utils -> @foxeet/data-access -> @foxeet/utils
   * De momento no se usa, si se necesitase habría que pasar el filtro desde fuera o mover Filter a data-access
   */
  // public getFilteredValidations(id: string | number, filter: Filter) {
  //   const { page = 1, size: pagesize = 10, data = {} } = filter?.getCurrentFilter() ?? {};
  //   const body = removeNullsAndUndefined(data);
  //   return this.post<typeof body, ValidationModel[]>(`${id}/Validations/ListPaged`, body, {
  //     params: this.setParams({
  //       page,
  //       pagesize,
  //       id,
  //     }),
  //   });
  // }

  public getAllValidations(id: string | number, body?: Partial<FoxeetValidationFilterModel>) {
    return this.post<typeof body, ValidationModel[]>(`${id}/Validations/ListAll`, body ?? {});
  }

  public calculateReportValidationAlerts(id: number) {
    return this.post<void, ValidationReportModel>(`${id}/Validations/Validate`);
  }

  // Massive edition
  // Tabs endpoints

  getDetailsMassiveEditionIdentification = (reportId: number): Observable<GroupMassiveModelData<AssetIdentificationDataModel>> => {
    return this.get<GroupMassiveModelData<AssetIdentificationDataModel>>(`${reportId}/MassiveAssetEdits/Identification`);
  };

  editMassiveEditionIdentification = (id: number, group: GroupMassiveModel): Observable<AssetTabMassiveResultModel[]> => {
    return this.put<GroupMassiveModel, AssetTabMassiveResultModel[]>(`${id}/MassiveAssetEdits/Identification`, group);
  };

  getDetailsMassiveEditionLocalization = (reportId: number): Observable<AssetTabModel<AssetLocalizationInformationModel>[]> => {
    return this.get<AssetTabModel<AssetLocalizationInformationModel>[]>(`${reportId}/MassiveAssetEdits/Localization`);
  };

  editMassiveEditionLocalization = (id: number, assets: AssetTabModel<AssetLocalizationInformationModel>[]): Observable<AssetTabMassiveResultModel[]> => {
    return this.put<AssetTabModel<AssetLocalizationInformationModel>[], AssetTabMassiveResultModel[]>(`${id}/MassiveAssetEdits/Localization`, assets);
  };

  getDetailsMassiveEditionSurfaces = (reportId: number): Observable<GroupMassiveModelData<AssetSurfacesInformationModel>> => {
    return this.get<GroupMassiveModelData<AssetSurfacesInformationModel>>(`${reportId}/MassiveAssetEdits/Surface`);
  };

  editMassiveEditionSurfaces = (id: number, group: GroupMassiveModel): Observable<AssetTabMassiveResultModel[]> => {
    return this.put<GroupMassiveModel, AssetTabMassiveResultModel[]>(`${id}/MassiveAssetEdits/Surface`, group);
  };

  calculateAssetSurfacesMassive(reportId: number, editedRows: GroupMassiveModel): Observable<GroupMassiveModel> {
    return this.post<typeof editedRows, GroupMassiveModel>(`${reportId}/SurfaceRCalculate`, editedRows);
  }

  getDetailsMassiveEditionTypology = (reportId: number): Observable<AssetTabModel<AssetTypologyInformationDto>[]> => {
    return this.get<AssetTabModel<AssetTypologyAndStateInformationDto>[]>(`${reportId}/MassiveAssetEdits/Typology`);
  };

  editMassiveEditionTypology = (id: number, assets: AssetTabModel<AssetSurfacesInformationModel>[]): Observable<AssetTabMassiveResultModel[]> => {
    return this.put<AssetTabModel<AssetSurfacesInformationModel>[], AssetTabMassiveResultModel[]>(`${id}/MassiveAssetEdits/Typology`, assets);
  };

  getDetailsMassiveEditionAssetStayAndFacilityInformation = (reportId: number): Observable<AssetTabModel<AssetStayAndFacilityInformationModel>[]> => {
    return this.get<AssetTabModel<AssetStayAndFacilityInformationModel>[]>(`${reportId}/MassiveAssetEdits/AssetStayAndFacilityInformation`);
  };

  editMassiveEditionAssetStayAndFacilityInformation = (id: number, assets: AssetTabModel<AssetStayAndFacilityInformationModel>[]): Observable<AssetTabMassiveResultModel[]> => {
    return this.put<AssetTabModel<AssetStayAndFacilityInformationModel>[], AssetTabMassiveResultModel[]>(`${id}/MassiveAssetEdits/AssetStayAndFacilityInformation`, assets);
  };

  getDetailsMassiveEditionTenures = (reportId: number): Observable<AssetTabModel<AssetTenureInformationModel>[]> => {
    return this.get<AssetTabModel<AssetTenureInformationModel>[]>(`${reportId}/MassiveAssetEdits/Tenures`);
  };

  editMassiveEditionTenures = (id: number, assets: AssetTabModel<AssetTenureInformationModel>[]): Observable<AssetTabMassiveResultModel[]> => {
    return this.put<AssetTabModel<AssetTenureInformationModel>[], AssetTabMassiveResultModel[]>(`${id}/MassiveAssetEdits/Tenures`, assets);
  };

  recalculateAll = (reportId: number): Observable<MultipleAssetRecalculationReportDataModel> => {
    return this.get(`${reportId}/RecalculateAllValorations`);
  };

  getMassiveValorationMethods = (reportId: number, valorationMethod: ValorationMethods, isFutureValoration: boolean) => {
    return this.get(`${reportId}/MassiveValorations/Valorations`, {
      params: this.setParams({
        valorationMethod,
        isFutureValoration,
      }),
    });
  };

  editMassiveValorationsMethods = (
    reportId: number,
    data: MassiveValorationsBaseModel<any>[],
    valorationMethod?: ValorationMethods,
    isFutureValoration?: boolean,
  ): Observable<any[]> => {
    return this.put(`${reportId}/MassiveValorations/Valorations`, data, {
      params: this.setParams({
        valorationMethod,
        isFutureValoration,
      }),
    });
  };
}
