import { BehaviorSubject, Observable } from 'rxjs';
import { ColDef, ICellEditorParams, ICellRendererParams } from 'ag-grid-community';
import { FormGroup } from '@angular/forms';
import { DynamicFormModel, IFormDataModel } from '../dynamic-form.model';
import { FormModel } from '../form.model';
import { AssetUses, InternationalizationTypes } from '../../enums';

export interface InputSurfacesDropdownParams {
  use: AssetUses;
  recalculateRow: (value: any) => void;
  callback?: (value: any) => void;
  controlNameOriginal?: string;
}

export interface InputDropdownBaseParams {
  options: BehaviorSubject<any[]>;
  enumName?: string;
  activeFilter?: boolean;
  activeVirtualScroll: boolean;
  replaceDropdownWithText?: (params: ICellEditorParams | ICellRendererParams) => boolean;
  controlNameOriginal?: string;
  controlNameToReplace?: string;
  checkStatusFrom?: string[];
  subscribeToChanges?: boolean;
  callback?: (value: any) => void;
  filterValueFormatter?: (value: any) => void;
  showConfirmModal?: boolean;
  confirmModalTitle?: string;
  isMarketStudy?: boolean;
}

export interface InputValuationStateEditorParams {
  initialValuesCallback?: (value: any) => void;
}

export interface InputTextAreaEditorParams {
  showButton: boolean;
  buttonName?: string;
  callback?: (value: any) => void;
}

export interface InputNumberCellRefactorParams {
  unit: InternationalizationTypes;
  digits: string;
  digitsFn: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellRefactorParams) => string;
  executeAfterCopyCell: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellRefactorParams, formGroupRowBeforeOrAfter: FormGroup) => any;
}
export interface InputNumberCellEditorParams {
  hideButtonCancel?: boolean;
  dependingToValidationForm?: string;
  recalculateRow: (data: any) => void;
  fieldDependingSuffix: string;
  subscribeToChanges?: boolean;
  callback?: (value: any) => void;
  suffix: string;
  showUnit: InternationalizationTypes;
  digitsInfo: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams) => string;
  enableFormat: boolean;
  showRenderer: (params: any) => boolean;

  // Modal
  openModalFn: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams) => any;
  openDynamicModalButtonIcon: string;
  openDynamicModalButtonIconMobile: string;
  openDynamicModal: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams) => boolean;
  dynamicModalTitle: string;
  dynamicModalSubTitle: string;
  dynamicModalConfig: (params) => IFormDataModel[][] | DynamicFormModel;
  dynamicModalForm: FormModel[];
  dynamicModalRequired: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams) => boolean;
  dynamicModalSubscribeToFormChanges: boolean;
  dynamicModalSubscribeToFields: { name: string; callback: (params, value) => void }[];
  executeBeforeOpenModal: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams) => any;
  executeOnConfirmModal: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams) => any;
  executeOnCloseModal: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams, data) => any;
  executeWhenCopyCell: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams, formGroupRowBeforeOrAfter: FormGroup) => any;
}

export interface InputButtonUploadCellEditorParams {
  dependingFields?: string[];
  callback?: (value: any) => void;
}

export interface InputDateCellEditorParams {
  canReset: boolean;
  minDate: any;
  maxDate: any;

  /* WEB PURPOSE */
  showTime: boolean;
  showLabel: boolean;
  dataType: string;

  /* MOBILE PURPOSE */
  displayFormat: string;
  pickerFormat: string;
}

export interface InputDateCellRendererParams {
  canReset: boolean;
  minDate: any;
  maxDate: any;

  /* WEB PURPOSE */
  showTime: boolean;
  showLabel: boolean;
  dataType: string;

  /* MOBILE PURPOSE */
  displayFormat: string;
  pickerFormat: string;
}

export interface InputModalItemsCellEditorParams {
  nameItems: string;
  formItemModel: IFormDataModel[][] | DynamicFormModel;
  formItemConfig: FormModel[];
}

export interface InputDropdownCellEditorParams extends InputDropdownBaseParams {
  dependingFields?: string[];
  valorationMethod?: number;
  recalculateRow: (data: any) => void;
  controlNameOriginal: string;
}

export type InputDropdownCellRendererParams = InputDropdownBaseParams;

export interface InputModalItemsCellEditorParams {
  nameItems: string;
  formItemModel: IFormDataModel[][] | DynamicFormModel;
  formItemConfig: FormModel[];
}

export interface IdColDefModel {
  id: string;
  errors?: string[];
}

export interface InputValuationStateColDefModel extends ColDef {
  initialValuesCallback?: (value: any) => void;
}

export interface InputSurfacesDropdownColDefModel extends ColDef {
  callback?: (value: any) => void;
  recalculateRow: (data: any) => void;
  controlNameOriginal?: string;
  executeWhenCopyCell: (params: (ICellEditorParams | ICellRendererParams) & any, formGroupRowBeforeOrAfter: FormGroup) => any;
}

export interface BaseInputColDefModel extends ColDef {
  options?: any;
}

export interface InputNumberColDefModel extends ColDef {
  hideButtonCancel?: boolean;
  recalculateRow: (data: any) => void;
  subscribeToChanges?: boolean;
  callback?: (value: any) => void;
  suffix: string;
  showUnit: InternationalizationTypes;
  digitsInfo: string;
  enableFormat: boolean;
  fieldDependingSuffix: string;
  showRenderer: (params: any) => boolean;

  // Modal
  openModalFn: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams) => any;
  openDynamicModalButtonIcon: string;
  openDynamicModalButtonIconMobile: string;
  openDynamicModal: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams) => boolean;
  dynamicModalTitle: string;
  dynamicModalSubTitle: string;
  dynamicModalConfig: (params) => IFormDataModel[][] | DynamicFormModel;
  dynamicModalForm: FormModel[];
  dynamicModalRequired: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams) => boolean;
  dynamicModalSubscribeToFormChanges: boolean;
  dynamicModalSubscribeToFields: { name: string; callback: (params, value) => void }[];
  executeBeforeOpenModal: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams) => any;
  executeOnConfirmModal: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams) => any;
  executeOnCloseModal: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams, data) => any;
  executeWhenCopyCell: (params: (ICellEditorParams | ICellRendererParams) & InputNumberCellEditorParams, formGroupRowBeforeOrAfter: FormGroup) => any;

  // REFACTOR
  unit: InternationalizationTypes;
  digits: string;
  digitsFn: string;
}

export interface InputTextAreaColDefModel extends ColDef {
  showButton: boolean;
  buttonName: string;
  callback?: (value: any) => void;
}

export interface InputModalItemsColDefModel extends ColDef {
  // callback?: (value: any) => void;
  nameItems: string;
  formItemModel: IFormDataModel[][] | DynamicFormModel;
  formItemConfig: FormModel[];
  defaultItemValue: (params: (ICellEditorParams | ICellRendererParams) & InputModalItemsCellEditorParams) => any;
}

export interface InputDateColDefModel extends ColDef {
  canReset: boolean;
  minDate: any;
  maxDate: any;

  /* WEB PURPOSE */
  showTime: boolean;
  showLabel: boolean;
  dataType: string;

  /* MOBILE PURPOSE */
  displayFormat: string;
  pickerFormat: string;
}

export interface InputDropdownColDefModel extends BaseInputColDefModel {
  options: (params: (ICellEditorParams | ICellRendererParams) & InputDropdownCellEditorParams) => Observable<any[]> | any[] | null;
  enumName?: string;
  dependingFields?: string[];
  activeFilter?: boolean | undefined;
  activeVirtualScroll?: boolean;
  replaceDropdownWithText?: (params: (ICellEditorParams | ICellRendererParams) & InputDropdownCellEditorParams) => boolean;
  controlNameOriginal?: string;
  controlNameToReplace?: string;
  checkStatusFrom?: string[];
  callback?: (value: any) => void;
  filterValueFormatter?: (params: (ICellEditorParams | ICellRendererParams) & InputDropdownCellEditorParams) => void;
  showConfirmModal?: boolean;
  confirmModalTitle?: string;
  recalculateRow: (data: any) => void;
  valorationMethod?: number;
  isMarketStudy?: boolean;
}

export interface InputDropdownControlColDefModel extends BaseInputColDefModel {
  options: (params: (ICellEditorParams | ICellRendererParams) & InputDropdownCellEditorParams) => Observable<any[]> | any[] | null;
  enumName?: string;
  dependingFields?: string[];
  activeFilter?: boolean | undefined;
  activeVirtualScroll?: boolean;
  replaceDropdownWithText?: (params: (ICellEditorParams | ICellRendererParams) & InputDropdownCellEditorParams) => boolean;
  controlNameOriginal?: string;
  controlNameToReplace?: string;
  checkStatusFrom?: string[];
  callback?: (value: any) => void;
  filterValueFormatter?: (params: (ICellEditorParams | ICellRendererParams) & InputDropdownCellEditorParams) => void;
  recalculateRow: (data: any) => void;
}

export enum ColumnType {
  INPUT_VALUATION_STATE,
  INPUT_TEXT,
  INPUT_TEXT_AREA,
  INPUT_NUMBER,
  INPUT_NUMBER_REFACTOR,
  INPUT_DROPDOWN,
  INPUT_CHECKBOX,
  INPUT_SWITCH,
  INPUT_TRIPLE_SWITCH,
  INPUT_SEARCHER,
  INPUT_STREETNAME,
  INPUT_DATE,
  INPUT_MODAL_ITEMS,
  INPUT_SURFACES_DROPDOWN,
  INPUT_BUTTON_UPLOAD,
  INPUT_DROPDOWN_CONTROL,
}

export enum ColumnDataType {
  STRING,
  INT,
  FLOAT,
  BOOLEAN,
}

export interface ColumnDataTypeConfig {
  column: string;
  columnDataType: ColumnDataType;
}

export interface ColumnConfig
  extends Partial<InputDropdownColDefModel>,
    Partial<InputValuationStateColDefModel>,
    Partial<InputNumberCellEditorParams>,
    Partial<InputNumberCellRefactorParams>,
    Partial<InputModalItemsColDefModel>,
    Partial<InputTextAreaEditorParams>,
    Partial<InputDateCellEditorParams>,
    Partial<InputSurfacesDropdownParams> {
  colType?: ColumnType | ((params: ICellEditorParams | ICellRendererParams) => ColumnType);
  field?: string;
  headerName: string;
  children?: ColumnConfig[];
}
