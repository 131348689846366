import { ValuationMassiveStates } from '../enums';
import { AssetTabInitialDetailsModel } from './assets.model';
import { ValuationExtraDataModel, ValuationInitialValuesModel } from './valuation.model';

export interface MassiveValorationsBaseModel<T> {
  id: number;
  name: string;
  parentId: number | null;
  hasChanges: boolean;
  massiveState: ValuationMassiveStates;
  reportsUniqueIncrementalId: number;
  valorationData: T;
  valorationInitialValues: ValuationInitialValuesModel<T>;
}
export interface MultipleEditionItemModel<T, S> {
  parentTempId: string;
  tempId: string;
  reportId: number;
  name: string;
  parentId: number;
  el: T;
  dataToPatch: S;
  dependingData: ValuationExtraDataModel | AssetTabInitialDetailsModel;
}
