import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { CacheService } from '../services/cache.service';
import {
  AppraisalCancellationEditModel,
  AppraisalClaimModel,
  AppraisalClaimSolveModel,
  AppraisalDashboardFilterModel,
  AppraisalDashboardModel,
  AppraisalEditInvoiceDataModel,
  AppraisalEditModel,
  AppraisalElapsedTimesDashboard,
  AppraisalExternalNotificationLog,
  AppraisalFileDataPickSizeModel,
  AppraisalIncidentEditModel,
  AppraisalIncidentModel,
  AppraisalIndexModel,
  AppraisalInvoiceDataModel,
  AppraisalManagersModel,
  AppraisalModalModel,
  AppraisalModel,
  AppraisalPurposes,
  AppraisalRevisionIndexModel,
  AppraisalSkipProfesionalStepsModel,
  AppraisalSuccededValidationModel,
  AppraisalSummary,
  AppraisalValidationAssetChangeModel,
  AppraisalVisitScheduleDateTimeModel,
  AppraisalWallCommentModel,
  AssetChangesModel,
  DashboardModel,
  EconomicValidationModel,
  FoxeetKeyValueModel,
  GenericTableData,
  KeyValueModel,
  MyWorkDto,
  ValidationReportModel,
  WorkflowItemClaimProceedModel,
} from '@foxeet/domain';
import { Observable } from 'rxjs';
import { WorkflowService } from '../services/workflow.service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppraisalsService extends WorkflowService {
  constructor(http: HttpClient, cache: CacheService) {
    super(http, 'Appraisals', cache);
  }

  getDataValidationModal<T>(id: number): Observable<T> {
    return this.get<T>(`${id}/AppraisalSummary`);
  }

  sendDataValidationModal(id: number, dataFormValidation: AppraisalSummary) {
    return this.put(`${id}/SucceededValidationConfirmingSummary`, dataFormValidation);
  }

  createWithAssets(body: AppraisalEditModel) {
    return this.post<typeof body, number>('CreateWithAssets', body);
  }

  startValidation(AppraisalId: number) {
    return this.put<null, void>(`${AppraisalId}/StartValidation`, null);
  }

  succeedValidation(AppraisalId: number, body: AppraisalSuccededValidationModel) {
    return this.put<typeof body, void>(`${AppraisalId}/SuccededValidation`, body);
  }

  failValidation(workflowItemId: number, reasonDescription: string) {
    const body = { workflowItemId, reasonDescription };
    return this.put<typeof body, void>(`${workflowItemId}/FailedValidation`, body);
  }

  setAsDelivered(appraisalId: number) {
    return this.put<null, void>(`${appraisalId}/Delivered`, null);
  }

  cancelAppraisal(body: AppraisalCancellationEditModel) {
    return this.put<AppraisalCancellationEditModel, void>(`${body.id}/Cancelled`, body);
  }

  advanceVisit(body: AppraisalSkipProfesionalStepsModel) {
    return this.put<AppraisalSkipProfesionalStepsModel, void>(`${body.appraisalId}/EditAsSkipedProfesional`, body);
  }

  getManagers(appraisalId: number, profileImageWidth: number = 46, profileImageHeight: number = 46) {
    return this.get<AppraisalManagersModel>(`${appraisalId}/Managers`, {
      observe: 'response',
      params: this.setParams({
        profileImageWidth,
        profileImageHeight,
      }),
    });
  }

  getInvoiceData(appraisalId: number) {
    return this.get<AppraisalInvoiceDataModel>(`${appraisalId}/InvoiceData`);
  }

  editInvoiceData(body: AppraisalEditInvoiceDataModel, appraisalId: number): Observable<string> {
    return this.put<typeof body, string>(`${appraisalId}/InvoiceData`, body);
  }

  public setSucceededValidationConfirmingChangesInAssets(appraisalId: number, assetChangesList: AssetChangesModel[]) {
    return this.put<typeof assetChangesList, ValidationReportModel>(`${appraisalId}/SucceededValidationConfirmingChangesInAssets`, assetChangesList);
  }

  public setSucceededValidationConfirmingAllChangesInAssets(appraisalId: number) {
    return this.put<null, ValidationReportModel>(`${appraisalId}/SucceededValidationConfirmingAllChangesInAssets`, null);
  }

  getDashboard(filter: AppraisalDashboardFilterModel): Observable<AppraisalDashboardModel> {
    return this.http.post<AppraisalDashboardModel>(`${this.apiBranch}/Dashboard`, filter).pipe(take(1));
  }

  getCForceDashboard(customerId: number, AppraisalPurpose: AppraisalPurposes, prescriptorId: number): Observable<DashboardModel> {
    return this.get<DashboardModel>('Dashboard', {
      params: this.setParams({
        customerId,
        AppraisalPurpose,
        prescriptorId,
      }),
    });
  }

  getIncidents(appraisalId: number): Observable<AppraisalIncidentModel[]> {
    return this.get<AppraisalIncidentModel[]>(`${appraisalId}/Incidents`);
  }

  createIncident(appraisalId: number, incident: AppraisalIncidentEditModel): Observable<void> {
    return this.post(`${appraisalId}/Incidents`, { ...incident, workflowItemId: appraisalId });
  }

  setAsResolvedIncident(appraisalId: number, incidentId: number, solutionDescription: string): Observable<void> {
    const body = { solutionDescription };
    return this.put(`${appraisalId}/Incidents/${incidentId}/SetAsResolved`, body);
  }

  reportToExternal(appraisalId: number, incidentId: number, externalDescription: string): Observable<void> {
    return this.post(`${appraisalId}/Incidents/${incidentId}/ReportToExternalContract`, { externalDescription });
  }

  getClaims(appraisalId: number): Observable<AppraisalClaimModel[]> {
    return this.get<AppraisalClaimModel[]>(`${appraisalId}/Claims`);
  }

  createClaim(appraisalId: number, reasonDescription: string): Observable<HttpResponse<void>> {
    const body = { reasonDescription };
    return this.post(`${appraisalId}/Claims`, body);
  }

  setAsResolvedClaim(appraisalId: number, claimId: number, solutionDescription: string): Observable<void> {
    const body = { solutionDescription };
    return this.put(`${appraisalId}/Claims/${claimId}/SetAsResolved`, body);
  }

  public setProceed(appraisalId: number, claimId: number, body: WorkflowItemClaimProceedModel): Observable<HttpResponse<void>> {
    return this.put(`${appraisalId}/Claims/${claimId}/SetProceed`, body);
  }

  public setAsResolved(appraisalId: number, claimId: number, body: AppraisalClaimSolveModel): Observable<void> {
    return this.put(`${appraisalId}/Claims/${claimId}/SetAsResolved`, body);
  }

  public getRevisions(appraisalId: number): Observable<AppraisalRevisionIndexModel[]> {
    return this.get(`${appraisalId}/Revisions`);
  }

  public setRevisionAsSolved(revisionId: number, appraisalId: number, solutionDescription: string): Observable<HttpResponse<null>> {
    const body = { id: revisionId, workflowItemId: appraisalId, solutionDescription };
    return this.put(`${appraisalId}/Revisions/${revisionId}/SetAsResolved`, body);
  }

  getWallComents(appraisalId: number): Observable<AppraisalWallCommentModel[]> {
    return this.get<AppraisalWallCommentModel[]>(`${appraisalId}/WallComments`);
  }

  createMessage(formData: { comment: string }, appraisalId: number, parentId?: number) {
    const body = { ...formData, parentId };
    return this.post<typeof body, number>(`${appraisalId}/WallComments`, body);
  }

  editMessage(formData: { comment: string }, messageId: number, appraisalId: number) {
    const body = { ...formData };
    return this.put<typeof body, void>(`${appraisalId}/WallComments/${messageId}`, body);
  }

  deleteMessage(messageId: number, appraisalId: number) {
    return this.delete<void>(`${appraisalId}/WallComments/${messageId}`);
  }

  public getLogsByAppraisalId<T>(appraisalId: any, body: any = null): Observable<T[]> {
    return this.post<typeof body, T[]>(`${appraisalId}/Logs/ListAll`, body);
  }

  public setTheAppraisalAsEconomicallyValidated(economicCalidation: EconomicValidationModel): Observable<void> {
    return this.put(`${economicCalidation.id}/EconomicallyValidated`, economicCalidation);
  }

  public getElapsedTimesDashboard(filter: AppraisalDashboardFilterModel): Observable<AppraisalElapsedTimesDashboard> {
    return this.http.post<AppraisalElapsedTimesDashboard>(`${this.apiBranch}/ElapsedTimesDashboard`, filter).pipe(take(1));
  }

  public getExternalNotificationByAppraisalId(appraisalId: any): Observable<AppraisalExternalNotificationLog> {
    return this.get<AppraisalExternalNotificationLog>(`${appraisalId}/ExternalNotifications`);
  }

  // MOBILE PURPOSES
  public getListPagedInExchangeForAppraiser(page: number, pagesize: number, body: any): Observable<GenericTableData<any[]>> {
    return this.post('ListPagedInExchangeForAppraiser', body, { params: this.setParams({ page, pagesize }) });
  }

  scheduleVisit(id: number, body: AppraisalVisitScheduleDateTimeModel) {
    return this.put(`${id}/VisitScheduleDateTime`, body);
  }

  startVisit(id: number) {
    return this.put(`${id}/StartVisit`, null);
  }

  finishVisit(id: number) {
    return this.put(`${id}/FinishVisit`, null);
  }

  _sendToRevisionService(id: number) {
    return this.put<null, any>(`${id}/DataSendFinished`, null);
  }

  myWorkListPaged<T>(page: number, size: number, body: any): Observable<T> {
    let params = new HttpParams();
    params = params.set('page', `${page}`);
    params = params.set('pagesize', `${size}`);
    return this.post<typeof body, T>(`MyWorkListPaged`, body, { params });
  }

  public getMyWork(): Observable<MyWorkDto[]> {
    return this.get<MyWorkDto[]>('MyWork');
  }

  public checkCanCreateAppraisal(): Observable<AppraisalModalModel> {
    return this.get<AppraisalModalModel>('CheckCanCreateAppraisal');
  }

  /**
   * Esta llamada se metió para obtener los datos de un appraisal para usarlos en la creación de uno nuevo (antes se hacía con el getById).
   * Es necesaria la llamada porque en back se ha añadido una lógica para comprobar según nuestro plan actual (BASIC/EXPERT) si podemos
   * realizar esa "copia" o existe alguna restricción.
   */
  public getAppraisalDataToCopy(id: number) {
    return this.get<AppraisalModel>(`${id}/AppraisalData`);
  }

  public getAppraisalExtraInformationPaged(page: number, pageSize: number, id: number): Observable<GenericTableData<FoxeetKeyValueModel>> {
    // FoxeetKeyValueFilterModel ---> body
    return this.post<unknown, GenericTableData<FoxeetKeyValueModel>>(
      `${id}/KeyValues/ListPaged`,
      {},
      {
        params: this.setParams({
          page,
          pageSize,
          id,
        }),
      },
    );
  }

  public getAppraisalRequestElementExtraInformationPaged(page: number, pageSize: number, id: number, requestElementId: number) {
    // FoxeetKeyValueFilterModel ---> body
    return this.post<unknown, GenericTableData<FoxeetKeyValueModel>>(
      `${id}/RequestElements/${requestElementId}/KeyValues/ListPaged`,
      {},
      {
        params: this.setParams({
          page,
          pageSize,
          id,
          requestElementId,
        }),
      },
    );
  }

  getAppraisalAllowedKeys(id: number, licenseeId: number | undefined) {
    const url = `${id}/KeyValues/AllowedToCreate`;
    return this.get<KeyValueModel[]>(url, {
      params: this.setParams({ licenseeId }),
    });
  }

  getAppraisalRequestElementAllowedKeys(id: number, requestElementId: number, licenseeId: number | undefined) {
    const url = `${id}/RequestElements/${requestElementId}/KeyValues/AllowedToCreate`;
    return this.get<KeyValueModel[]>(url, {
      params: this.setParams({ licenseeId }),
    });
  }

  addNewDataToAppraisal(id: number, body: FoxeetKeyValueModel) {
    const url = `${id}/KeyValues`;
    return this.post<FoxeetKeyValueModel, number>(url, body, { params: this.setParams({ id }) });
  }

  addNewDataToAppraisalRequestElement(id: number, requestElementId: number, body: FoxeetKeyValueModel) {
    const url = `${id}/RequestElements/${requestElementId}/KeyValues`;
    return this.post<FoxeetKeyValueModel, number>(url, body, {
      params: this.setParams({ id, requestElementId }),
    });
  }

  removeDataAppraisal(appraisalId: number, keyValueId: number) {
    return this.delete(`${appraisalId}/KeyValues/${keyValueId}`);
  }

  removeDataAppraisalRequestElement(appraisalId: number, orderRequestElementId: number, keyValueId: number) {
    return this.delete(`${appraisalId}/RequestElements/${orderRequestElementId}/KeyValues/${keyValueId}`);
  }

  public getSuccededValidation(id: string | number, valorationTotalValue?: number) {
    return this.put<null, any>(`${id}/SuccededValidation`, null, { params: this.setParams({ valorationTotalValue }) });
  }

  public getSucceededValidationConfirmingChangesInAssets(id: string | number, body: AssetChangesModel[]) {
    return this.put<AssetChangesModel[], any>(`${id}/SucceededValidationConfirmingChangesInAssets`, body);
  }

  public getSucceededValidationConfirmingAllChangesInAssets(id: string | number) {
    return this.put<null, any>(`${id}/SucceededValidationConfirmingAllChangesInAssets`, null);
  }

  public getValidateAsChangeOfEntity(id: string | number, body: AppraisalValidationAssetChangeModel) {
    return this.put<typeof body, any>(`${id}/ValidateAsChangeOfEntity`, body);
  }

  public getPrecedentsListById(id: number, page: number, pagesize: number, filter: any) {
    return this.post<typeof filter, GenericTableData<AppraisalIndexModel>>(`${id}/Antecedents/ListPaged`, filter, {
      params: this.setParams({
        page,
        pagesize,
      }),
    });
  }

  public listAntecedentBde(id: number): Observable<AppraisalIndexModel[]> {
    return this.post(`${id}/Antecedents/BdE`);
  }

  public existAntecedentBde(id: number): Observable<boolean> {
    return this.post(`${id}/Antecedents/ExistsBdE`);
  }

  public matchValuesAntecedentBde(id: number): Observable<boolean> {
    return this.post(`${id}/Antecedents/MatchValues`);
  }

  public getDataFormDataPickVinculations(id: number, body: any) {
    return this.get<AppraisalFileDataPickSizeModel[]>(`${id}/GetDataFromDataPickVinculations`, body);
  }

  public addDataFromDataPickVinculations(id: number, body: any) {
    return this.post(`${id}/ImportDataFromDataPickVinculations`, body);
  }
}
