import {
  AppraisalOrderTypes,
  AppraisalVisitTypes,
  AssetDataSourceTypes,
  AssetInstructions,
  AssetSections,
  AssetStates,
  AssetSubTypologies,
  AssetSurfaceIds,
  AssetSurfaceTypes,
  AssetTabs,
  AssetTypologies,
  AssetUses,
  LogItemActions,
  ReportActions,
  ResidenceTypes,
  ValidationTypes,
} from '../enums';
import { OrNull } from '../interfaces/global.interfaces';
import { AssetLocalizationInformation } from './asset-localization.model';
import { BuildingInformation } from './building-information.model';
import { EnumModel } from './enums.models';
import { AssetIdentificationDataModel, AssetTypologyAndStateInformationEditModelBase } from './new-tab-models';

/**@todo revisar con alberto */
export interface AssetCreateModel {
  assetChildren: AssetCreateModel[];
  assetIdentification: AssetIdentificationDataModel;
  assetLocalization: AssetLocalizationInformation;
  assetTypologies: AssetTypologyAndStateInformationEditModelBase;
  assetSurfaces: AssetSurfacesInformationDto;
  parentAssetId?: number;
  buildingInformation: BuildingInformation;
}

export interface AssetIndexModel {
  AssetId: number;
  ParentAssetId: number;
  ParentWorkflowItemAssetId: number;
  WorkflowItemReportId: number;
  AddedDateTime: Date;
  AddedUserId: number;
  AddedUser: number; // UserItemModel;
  LastUpdatedDateTime: Date;
  LastUpdatedUserId: number;
  CadastralReference: string;
  Idufir: string;
  RegisteredPropertyNumber: string;
  PropertyRegistrationName: string;
  PropertyRegistrationCode: string;
  StreetName: string;
  StreetNumber: string;
  Volume: string;
  Book: string;
  Paper: string;
  Country: string;
  Locality: string;
  Province: string;
  AssetInstruction: AssetInstructions;
  AssetState: AssetStates;
  AssetUse: AssetUses;
  AssetTypology: AssetTypologies;
  AssetSubTypology: AssetSubTypologies;
  Name: string;
}

export interface LocationInformationIndexModel {
  // LocalityLocationType: LocalityLocationTypes;
  TypeOfCore: string;
  RentLevel: string;
  Population: string;
  Evolution: string;
}

export interface AssetSurfaceIndexModel {
  AssetId: number;
  WorkflowItemAssetId: number;
  AssetSurfaceType: AssetSurfaceTypes;
  TotalUsefulSurface: number;
  TotalBuiltSurfaceWithoutCommonSurfaces: number;
  TotalBuiltSurfaceWithCommonSurfaces: number;
  TotalAdoptedSurface: number;
  TotalPlotSurface: number;
  TotalCoveredTerraceSurface: number;
  TotalUncoveredTerraceSurface: number;
}

export interface AssetSurfaceData {
  totalPlotSurface?: number;
  totalUsefulSurface?: number;
  totalUsefulSurfaceId?: number;
  totalBuiltSurfaceWithoutCommonSurfaces?: number;
  totalBuiltSurfaceWithoutCommonSurfacesId?: number;
  totalBuiltSurfaceWithCommonSurfaces?: number;
  totalBuiltSurfaceWithCommonSurfacesId?: number;
  totalCoveredTerraceSurface?: number;
  totalUncoveredTerraceSurface?: number;

  totalPlotSurfaceId?: AssetSurfaceIds;
  totalCoveredTerraceSurfaceId?: AssetSurfaceIds;
  totalUncoveredTerraceSurfaceId?: AssetSurfaceIds;
  allFields?: string[];
  totalCompletedPercentage?: number;
  totalRequiredCompletedPercentage?: number;

  rCoefficient?: number;
  isRCoefficientEdited?: boolean;
}

export interface AssetValorationsModel {
  ValorationValue: number;
  IsSameAsActive: boolean;
  RecordId: string;
  OrderType: AppraisalOrderTypes;
  VisitType: AppraisalVisitTypes;
  ValidationFinishDatetime: Date;
}

export interface AssetFileIndexModel {
  MainAssetId: number;
  FileId: number;
  AssetStayId: number;
  FileName: string;
  FilePath: string;
  FileWebPath: string;
  AddedDateTime: Date;
  Name: string;
  Description: string;
}

export interface AssetTreeNodeIndexModel {
  Name: string;
  Id: number;
  ParentId: number;
  AssetChildren: AssetTreeNodeIndexModel[];
}

export interface LogItemModel {
  assetSection: AssetSections;
  field: string;
  oldValue: string;
  newValue: string;
  id: string;
  action: LogItemActions;
}

export interface AssetLogItemIndexModel {
  id: number;
  assetId: number;
  fieldChanges: LogItemModel[];
  addedDateTime: string;
  addedUserId: number;
  addedUserFullName: string;
}

export interface AssetChangesModel {
  id: number;
  assetId: number;
  assetSection: AssetSections;
  assetSectionTranslateKey: string;
  field: string;
  type: string;
  genericType: string;
  oldValue: Record<string, unknown>;
  oldValueTranslateKey: string;
  newValue: Record<string, unknown>;
  newValueTranslateKey: string;
  itemList: AssetChangesModel[];
}

export interface AssetMarket {
  featuresMarket: string;
  offer: string;
  demand: string;
  demandRevaluationExpectations: string;
  existsComparableMarket: boolean;
}

export interface AssetEnvironment {
  jobOccupation: string;
  characteristicAntiquity: string;
  architecturalHomogeneity: string;
  infrastructures: string;
  equipmentAndServices: string;
  communications: string;
  parkings: string;
  buildingDevelopment: string;
  renovation: string;
}

export interface IProvinceFormData {
  name: string;
  ineCod: number;
  translatedLabel: string;
}

export interface ILocalityFormData {
  name: string;
  ineCod: number;
  ineCodMun: number;
  ineCodLocality: number;
  translatedLabel: string;
}

export interface IStreetNameFormData {
  viaId: number;
  dgcCodVia: number;
  ineCodVia: string;
  name: string;
  translatedLabel: string;
}

export interface ICountryThreeLetterISORegionNameFormData {
  name: string;
  englishName: string;
  nativeName: string;
  threeLetterIso: string;
  translatedLabel: string;
}

//  Nuevos modelos

export interface AssetTabConfiguration {
  tabId: AssetTabs;
  order: number;
  isVisible: boolean;
  isClickable: boolean;
  isComplete: boolean;
  completedPercentage: number;
  isRequiredComplete: boolean;
  requiredCompletedPercentage: number;
  // Validations
  errorCount?: number;
  warningCount?: number;
  infoCount?: number;
}

export interface AssetInitialDetailModel {
  name: string;
  profileImageFileWebPath: string;
  isEconomicExploitation: boolean;
  tree: AssetTreeNodeIndexModel[];
  currentUserActionsPermisions: ReportActions;
  assetUse: AssetUses;
  isResidentialUse: boolean;
  clusterId: number;
  clusterName: string;
  isClustered: boolean;
  isRevalorationNeeded: boolean;
  future_IsRevalorationNeeded: boolean;
  isUnfinished: boolean;
  isTerrain: boolean;
  tabConfigurations: AssetTabConfiguration[];
  assetIdentificationTotalCompletedPercentage: number;
  assetIdentificationTotalCompletedPercent: number;
  isAssetIdentificationTotalCompleted: boolean;
  assetIdentificationTotalRequiredCompletedPercentage: number;
  assetIdentificationRequiredCompletedPercent: number;
  isAssetIdentificationRequiredCompleted: boolean;
  locationTotalCompletedPercentage: number;
  locationTotalCompletedPercent: number;
  isLocationTotalCompleted: boolean;
  locationRequiredCompletedPercentage: number;
  locationRequiredCompletedPercent: number;
  isLocationRequiredCompleted: boolean;
  typologiesTotalCompletedPercentage: number;
  typologiesTotalCompletedPercent: number;
  isTypologiesTotalCompleted: boolean;
  typologiesRequiredCompletedPercentage: number;
  typologiesRequiredCompletedPercent: number;
  isTypologiesRequiredCompleted: boolean;
  cadastralSurfacesTotalCompletedPercentage: number;
  cadastralSurfacesTotalCompletedPercent: number;
  isCadastralSurfacesTotalCompleted: boolean;
  cadastralSurfacesTotalRequiredCompletedPercentage: number;
  cadastralSurfacesRequiredCompletedPercent: number;
  isCadastralSurfacesRequiredCompleted: boolean;
  registeredSurfacesTotalCompletedPercentage: number;
  registeredSurfacesTotalCompletedPercent: number;
  isRegisteredSurfacesTotalCompleted: boolean;
  registeredSurfacesTotalRequiredCompletedPercentage: number;
  registeredSurfacesRequiredCompletedPercent: number;
  isRegisteredSurfacesRequiredCompleted: boolean;
  checkedSurfacesTotalCompletedPercentage: number;
  checkedSurfacesTotalCompletedPercent: number;
  isCheckedSurfacesTotalCompleted: boolean;
  checkedSurfacesTotalRequiredCompletedPercentage: number;
  checkedSurfacesRequiredCompletedPercent: number;
  isCheckedSurfacesRequiredCompleted: boolean;
  surfacesTotalCompletedPercentage: number;
  surfacesTotalCompletedPercent: number;
  isSurfacesTotalCompleted: boolean;
  surfacesRequiredCompletedPercentage: number;
  surfacesRequiredCompletedPercent: number;
  isSurfacesRequiredCompleted: boolean;
  stateTotalCompletedPercentage: number;
  stateTotalCompletedPercent: number;
  isStateTotalCompleted: boolean;
  stateRequiredCompletedPercentage: number;
  stateRequiredCompletedPercent: number;
  isStateRequiredCompleted: boolean;
  buildingInformationTotalCompletedPercentage: number;
  buildingInformationTotalCompletedPercent: number;
  isBuildingInformationTotalCompleted: boolean;
  buildingInformationTotalRequiredCompletedPercentage: number;
  buildingInformationRequiredCompletedPercent: number;
  isBuildingInformationRequiredCompleted: boolean;
  facilitiesTotalCompletedPercentage: number;
  facilitiesTotalCompletedPercent: number;
  isFacilitiesTotalCompleted: boolean;
  facilitiesTotalRequiredCompletedPercentage: number;
  facilitiesRequiredCompletedPercent: number;
  isFacilitiesRequiredCompleted: boolean;
  staysCompletedPercentage: number;
  staysCompletedPercent: number;
  isStaysCompleted: boolean;
  staysRequiredCompletedPercentage: number;
  staysRequiredCompletedPercent: number;
  isStaysRequiredCompleted: boolean;
  tenureCompletedPercentage: number;
  tenureCompletedPercent: number;
  isTenureCompleted: boolean;
  tenureRequiredCompletedPercentage: number;
  tenureRequiredCompletedPercent: number;
  isTenureRequiredCompleted: boolean;
  environmentCompletedPercentage: number;
  environmentCompletedPercent: number;
  isEnvironmentCompleted: boolean;
  environmentRequiredCompletedPercentage: number;
  environmentRequiredCompletedPercent: number;
  isEnvironmentRequiredCompleted: boolean;
  buildingTerrainDescriptionTotalCompletedPercentage: number;
  buildingTerrainDescriptionTotalCompletedPercent: number;
  isBuildingTerrainDescriptionTotalCompleted: boolean;
  buildingTerrainDescriptionTotalRequiredCompletedPercentage: number;
  buildingTerrainDescriptionRequiredCompletedPercent: number;
  isBuildingTerrainDescriptionRequiredCompleted: boolean;
  valorationsFinalCompletedPercentage: number;
  valorationsFinalRequiredCompletedPercentage: number;
  valorationsFinalCompletedPercent: number;
  isFinalValorationsCompleted: boolean;
  valorationsFinalRequiredPercent: number;
  isFinalValorationsRequiredCompleted: boolean;
  valorationsTotalCompletedPercentage: number;
  valorationsRequiredCompletedPercentage: number;
  valorationsTotalCompletedPercent: number;
  isValorationsTotalCompleted: boolean;
  valorationsRequiredCompletedPercent: number;
  isValorationsRequiredCompleted: boolean;
  future_ValorationsTotalCompletedPercentage: number;
  future_ValorationsRequiredCompletedPercentage: number;
  future_ValorationsTotalCompletedPercent: number;
  future_IsValorationsTotalCompleted: boolean;
  future_ValorationsRequiredCompletedPercent: number;
  future_IsValorationsRequiredCompleted: boolean;
  urbanisticSituationTotalCompletedPercentage: number;
  urbanisticSituationTotalCompletedPercent: number;
  isUrbanisticSituationTotalCompleted: boolean;
  urbanisticSituationTotalRequiredCompletedPercentage: number;
  urbanisticSituationRequiredCompletedPercent: number;
  isUrbanisticSituationRequiredCompleted: boolean;
  terrainInformationTotalCompletedPercentage: number;
  terrainInformationTotalCompletedPercent: number;
  terrainInformationTotalRequiredCompletedPercentage: number;
  isTerrainInformationTotalCompleted: boolean;
  terrainInformationRequiredCompletedPercent: number;
  isTerrainInformationRequiredCompleted: boolean;
  workPlanStateTotalCompletedPercentage: number;
  workPlanStateTotalCompletedPercent: number;
  isWorkPlanStateTotalCompleted: boolean;
  workPlanStateTotalRequiredCompletedPercentage: number;
  workPlanStateRequiredCompletedPercent: number;
  isWorkPlanStateRequiredCompleted: boolean;
  isWorkPlanStateRecalculationNeeded: boolean;
  typologyAndStateCompletedPercentage: number;
  typologyAndStateCompletedPercent: number;
  isTypologyAndStateCompleted: boolean;
  typologyAndStateRequiredCompletedPercentage: number;
  typologyAndStateRequiredCompletedPercent: number;
  isTypologyAndStateRequiredCompleted: boolean;
  facilitiesAndStaysCompletedPercentage: number;
  facilitiesAndStaysCompletedPercent: number;
  isFacilitiesAndStaysTotalCompleted: boolean;
  facilitiesAndStaysRequiredCompletedPercentage: number;
  facilitiesAndStaysRequiredCompletedPercent: number;
  isFacilitiesAndStaysRequiredCompleted: boolean;
  isAllAssetCompleted: boolean;
  isAllRequiredAssetCompleted: boolean;
  visitForm_TotalCompletedPercent: number;
  visitForm_RequiredCompletedPercent: number;
  visitForm_IsAllCompleted: boolean;
  visitForm_IsAllRequiredCompleted: boolean;
  visitForm_SurfacesAndFacilitiesAndStaysTotalCompletedPercent: number;
  visitForm_SurfacesAndFacilitiesAndStaysRequiredCompletedPercent: number;
  visitForm_TypologyAndStateTotalCompletedPercent: number;
  visitForm_TypologyAndStateRequiredCompletedPercent: number;
  visitForm_EnvironmentInformationTotalCompletedPercent: number;
  visitForm_BuildingInformationTotalCompletedPercent: number;
  visitForm_EnvironmentInformationRequiredCompletedPercent: number;
  visitForm_BuildingInformationRequiredCompletedPercent: number;
  hasValorations: boolean;
  commonAssetHasTerrainElements: boolean;
  commonAssetHasFinishedElements: boolean;
  commonAssetHasUnfinishedElements: boolean;
}

export interface AssetTypologyInformationDto {
  state: AssetStates;
  use: AssetUses;
  typology: AssetTypologies;
  subTypology: AssetSubTypologies;
  bathroomCount: number;
  bedroomCount: number;
  residenceType: ResidenceTypes;
  expedient: string;
  provisionalQualificationDateTime: string;
  definitiveQualificationDateTime: string;
  protectionTimeLimit: number;
}

export interface AssetSurfacesInformationDto {
  cadastralSurfaces: OrNull<AssetSurfaceData>;
  checkedSurfaces: OrNull<AssetSurfaceData>;
  registeredSurfaces: OrNull<AssetSurfaceData>;
  surfacesObservations: string;
}

export interface AssetSurfacesInformationModel {
  cadastralSurfaces: AssetSurfaceData;
  registeredSurfaces: AssetSurfaceData;
  checkedSurfaces: AssetSurfaceData;
  surfacesObservations: string;
}

export interface AssetSurfacesEditModel {
  cadastralSurfaces: AssetSurfaceData;
  registeredSurfaces: AssetSurfaceData;
  checkedSurfaces: AssetSurfaceData;
  surfacesObservations: string;
  id: number;
}

export interface LocalityAndCensusSectionInformation {
  localityTypeOfCore: string;
  localityPopulation: number;
  localityPopulationEvolution: number;
  localityUnemploymentRate: number;
  localityAvgAnnualIncome: number;
  censusSectionTypeOfCore: string;
}

export interface MassiveChangesModel {
  column: string;
  value: string;
}
export interface AssetTabModelMassive<T> {
  tempId: string;
  data?: AssetTabModel<T>;
  changes: MassiveChangesModel[];
}

export interface MassiveModel {
  tempId: string;
  changes: MassiveChangesModel[];
}

export interface GroupMassiveModel {
  tempId: string;
  massiveModels: MassiveModel[];
}

export interface GroupMassiveModelData<T> {
  tempId: string;
  massiveModels: AssetTabModelMassive<T>[];
}

export interface FormMassiveModel<T> {
  data: T;
  dataSource: AssetDataSourceTypes;
  id: number;
  reportId: number;
  tempId: string;
  parentTempId: string;
}

export interface AssetTabModel<T> {
  id: number;
  data: T;
  dataSource?: AssetDataSourceTypes;
  dataSourceOptions?: EnumModel[];
  initialDetails?: AssetTabInitialDetailsModel;
  parentId: number | null;
  name: string | null;
}

export interface AssetTabInitialDetailsModel {
  uses?: AssetUses[];
  typologies?: AssetTypologies[];
  states?: AssetStates[];
  isEconomicExploitation?: boolean;
  hasValorations?: boolean;
  isClustered?: boolean;
  validationType?: ValidationTypes;
}

export interface AssetMultipleRecalculationModel {
  assetName: string;
  errors: string;
}
